import * as Switch from '@radix-ui/react-switch';
import clsx from 'clsx';
import React from 'react';

export interface ToggleProps {
  'aria-label': string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  size?: 'small' | 'default';
}

const Toggle: React.FC<ToggleProps> = ({
  size = 'default',
  checked,
  onCheckedChange,
  ...props
}) => {
  return (
    <form aria-label={props['aria-label']}>
      <Switch.Root
        className={clsx(
          'relative inline-flex cursor-pointer items-center rounded-full border-[.7px]',
          {
            'h-[32px] w-[60px]': size === 'default',
            'h-[26px] w-[54px]': size === 'small',
            'border-neutral-300 bg-neutral-200': !checked,
            'border-brandMidnight bg-blue-light': checked,
          }
        )}
        checked={checked}
        onCheckedChange={onCheckedChange}
      >
        <span
          className={clsx('left-[15%] text-captionLight', {
            hidden: !checked,
            'absolute text-brandMidnight': checked,
          })}
        >
          ON
        </span>
        <span
          className={clsx('right-[12%] text-captionLight', {
            'absolute text-gray-600': !checked,
            hidden: checked,
          })}
        >
          OFF
        </span>
        <Switch.Thumb
          className={clsx(
            'inline-block transform rounded-full border transition-transform duration-200',
            {
              'h-[24px] w-[24px] translate-x-[4px] border-neutral-300 bg-neutral-0':
                !checked && size === 'default',
              'h-[18px] w-[18px] translate-x-[4px] border-neutral-300 bg-neutral-0':
                !checked && size === 'small',
              'h-[24px] w-[24px] translate-x-[30px] border-brandMidnight bg-brandMidnight':
                checked && size === 'default',
              'h-[18px] w-[18px] translate-x-[30px] border-brandMidnight bg-brandMidnight':
                checked && size === 'small',
            }
          )}
        />
      </Switch.Root>
    </form>
  );
};

export default Toggle;
