export interface AnimationDelay {
  onExpand: number;
  onCollapse: number;
}
interface ExpandAnimationProps {
  children: React.ReactNode;
  expanded: boolean;
  duration?: number;
  delay?: AnimationDelay;
}

export const ExpandAnimation: React.FC<ExpandAnimationProps> = ({
  children,
  expanded,
  delay,
  duration = 250,
}) => {
  let appliedDelay = '';
  if (expanded && delay?.onExpand) {
    appliedDelay = `${delay.onExpand}ms`;
  } else if (!expanded && delay?.onCollapse) {
    appliedDelay = `${delay.onCollapse}ms`;
  }
  return (
    <div
      className="transition-grid grid"
      style={{
        gridTemplateRows: expanded ? '1fr' : '0fr',
        transitionDelay: appliedDelay || '0ms',
        transitionDuration: `${duration}ms`,
      }}
    >
      <div className={`${expanded ? '' : 'overflow-hidden'}`}>{children}</div>
    </div>
  );
};
