import {
  IncentiveResponse,
  JDPowerIncentive,
} from '@/lib/schema/incentives/types';

export const findPurchaseFederalIncentive = (incentive: JDPowerIncentive) =>
  incentive.id === 718;

export const findUsedPurchaseFederalIncentive = (incentive: JDPowerIncentive) =>
  incentive.id === 666;

export const filterOutLeasedIncentives = (incentives: JDPowerIncentive[]) =>
  incentives.filter((incentive) => incentive.id !== 681);

export const isEvaluatedIncentive = (incentive: JDPowerIncentive) =>
  [666, 718, 394].includes(incentive.id);

export const findCanadianPurchaseFederalIncentive = (
  incentive: JDPowerIncentive
) => incentive.id === 232;

export const returnPurchaseFederalIncentive = (
  incentives: JDPowerIncentive[]
) =>
  incentives.filter(
    (incentive) =>
      findPurchaseFederalIncentive(incentive) ||
      findUsedPurchaseFederalIncentive(incentive) ||
      findCanadianPurchaseFederalIncentive(incentive)
  );

export const getFederalTotal = (incentives: JDPowerIncentive[]) =>
  returnPurchaseFederalIncentive(incentives).reduce(
    (total, incentive: JDPowerIncentive) => {
      return total + (incentive.evaluation.amount_in_purchase || 0);
    },
    0
  );

export const getTotalIncentiveAmount = (incentives: JDPowerIncentive[]) =>
  incentives.reduce((total, incentive: JDPowerIncentive) => {
    if (incentive.grantor === 'Federal' && incentive.point_of_sale) {
      return total + incentive.evaluation.amount_in_purchase;
    }

    if (incentive.point_of_sale && incentive.max_amount) {
      return total + incentive.max_amount;
    }

    return total;
  }, 0);

export const getTotalRebateAmount = (incentives: JDPowerIncentive[]): number =>
  incentives.reduce((total, incentive: JDPowerIncentive) => {
    if (incentive.grantor === 'Federal' && !incentive.point_of_sale) {
      return total + incentive.evaluation.amount_in_purchase;
    }

    if (!incentive.point_of_sale && isEvaluatedIncentive(incentive)) {
      return total + incentive.evaluation.amount_in_purchase;
    }

    if (!incentive.point_of_sale && incentive.max_amount) {
      return total + incentive.max_amount;
    }

    return total;
  }, 0);

export const formatIncentivesFromAPI = (data: IncentiveResponse | null) => {
  if (data === null) {
    return {
      federal: [],
      state: [],
      local: [],
    };
  }
  const federalIncentives = returnPurchaseFederalIncentive(data?.Federal || []);
  const stateIncentives = data?.State || [];
  const localIncentives = data?.local || [];

  return {
    federal: federalIncentives,
    state: stateIncentives,
    local: localIncentives,
  };
};

export const isExcludedBySelectedIncentives = (
  selectedIncentives: JDPowerIncentive[],
  incentive: JDPowerIncentive
) => {
  return incentive.mutually_exclusive_programs.some((excludedIncentive) =>
    selectedIncentives.some(
      (selectedIncentive) =>
        selectedIncentive.id === parseInt(excludedIncentive, 10)
    )
  );
};

export type GroupedIncentives = {
  total: number;
  monetaryIncentives: JDPowerIncentive[];
  rebates: JDPowerIncentive[];
  nonMonetaryIncentives: JDPowerIncentive[];
};

export const getGroupedIncentives = (incentives: JDPowerIncentive[]) =>
  incentives.reduce<GroupedIncentives>(
    (incentiveObj, incentive) => {
      // eslint-disable-next-line no-param-reassign
      incentiveObj.total += 1;

      if (
        (incentive.max_amount && incentive.point_of_sale) ||
        findUsedPurchaseFederalIncentive(incentive)
      ) {
        incentiveObj.monetaryIncentives.push(incentive);
      } else if (incentive.max_amount && !incentive.point_of_sale) {
        incentiveObj.rebates.push(incentive);
      } else if (!incentive.max_amount) {
        incentiveObj.nonMonetaryIncentives.push(incentive);
      }

      return incentiveObj;
    },
    {
      total: 0,
      monetaryIncentives: [],
      rebates: [],
      nonMonetaryIncentives: [],
    }
  );

export const calculatedRebateIncentives = (
  incentives: JDPowerIncentive[],
  groupedIncentives: GroupedIncentives
) =>
  incentives.reduce(
    (total, incentive) => {
      if (
        groupedIncentives.monetaryIncentives.some(
          (monetaryIncentive) => monetaryIncentive.id === incentive.id
        )
      ) {
        const newTotal =
          total.incentives +
          ((isEvaluatedIncentive(incentive)
            ? incentive?.evaluation.amount_in_purchase
            : incentive?.max_amount) || 0);

        return {
          rebate: total.rebate,
          incentives: newTotal,
        };
      }

      if (
        groupedIncentives.rebates.some((rebate) => rebate.id === incentive.id)
      ) {
        const newTotal =
          total.rebate +
          ((isEvaluatedIncentive(incentive)
            ? incentive?.evaluation.amount_in_purchase
            : incentive?.max_amount) || 0);

        return {
          rebate: newTotal,
          incentives: total.incentives,
        };
      }
      return total;
    },
    {
      rebate: 0,
      incentives: 0,
    }
  );
