import Button, {
  ButtonProps,
  ButtonVariants,
} from '@/components/atoms/Button/Button';
import {
  AuthDialog,
  AuthDialogProps,
} from '@/components/organisms/AuthDialog/AuthDialog';
import { useAuth } from '@/hooks/useAuth';
import { BellAlertIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { MouseEventHandler, useEffect, useState } from 'react';
import { Dot } from './Dot';

export interface CheckAvailabilityButtonProps
  extends Partial<
      Pick<
        AuthDialogProps,
        'backGroundImage' | 'isAuthDialogOpen' | 'setIsAuthDialogOpen'
      >
    >,
    Pick<ButtonProps, 'size'> {
  contactedDealer: boolean;
  shadow?: boolean;
  onClick: () => Promise<void>;
}

export const CheckAvailabilityButton: React.FC<
  CheckAvailabilityButtonProps
> = ({
  contactedDealer: alreadyContactedDealer = false,
  onClick,
  backGroundImage = '',
  isAuthDialogOpen = false,
  setIsAuthDialogOpen = () => {},
  size,
  shadow = false,
}) => {
  const { user } = useAuth();
  const [contactedDealer, setContactedDealer] = useState(
    alreadyContactedDealer
  );

  const { mutateAsync, isPending: isSendingMessage } = useMutation({
    mutationFn: onClick,
  });

  const handleClickCheckAvailability: MouseEventHandler<
    HTMLButtonElement
  > = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (user) {
      await mutateAsync();
      setContactedDealer(true);
    } else {
      setIsAuthDialogOpen(true);
    }
  };

  useEffect(() => {
    if (!user && contactedDealer) {
      setContactedDealer(false);
    }
  }, [user, contactedDealer]);

  return (
    <>
      <Button
        aria-label="Check availability"
        shadow={shadow ? 'shadow-ml m:shadow-none' : 'shadow-none'}
        size={size}
        icon={
          contactedDealer
            ? {
                icon: <CheckCircleIcon className="h-xl w-xl" />,
                position: 'left',
              }
            : undefined
        }
        variant={ButtonVariants.Tertiary}
        disabled={contactedDealer || isSendingMessage}
        loading={isSendingMessage}
        type="button"
        onClick={handleClickCheckAvailability}
      >
        Check availability
      </Button>
      <AuthDialog
        isAuthDialogOpen={isAuthDialogOpen}
        backGroundImage={backGroundImage}
        setIsAuthDialogOpen={setIsAuthDialogOpen}
        renderIcon={
          <>
            <BellAlertIcon className="h-xl w-xl" />
            <div className="absolute left-[54%]">{Dot}</div>
          </>
        }
        title="Check availability"
        description="Sign in or create an account to keep updated on this vehicle."
      />
    </>
  );
};
